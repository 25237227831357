@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap");

.login-main-container {
  height: 100vh;
  display: flex;
}

.sub-left-container {
  width: 35%;
  background-color: #fff;
  filter: drop-shadow(6px 18px 60px rgba(13, 80, 96, 0.25));
  border-radius: 0px 30px 30px 0px;
  margin-top: 5%;
  margin-bottom: 4%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sub-right-container {
  width: 55%;
  margin-top: 6%;
  margin-left: 20vh;
}

.login-samishti-logo {
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-right: 2vh;
  margin-top: 5vh;
  margin-bottom: 4vh;
}

.login-header-text {
  font-size: 26px;
  margin-bottom: 0px;
}

.raleway-font {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: #2687d7;
}

.login-input-div {
  padding-bottom: 25px;
}

.login-inputbox {
  border-color: #fff;
  text-decoration: none;
  background-color: #fff;
}

.login-left-inner-container {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.btn-style {
  display: flex;
  justify-content: space-around;
}

.login-signup-button {
  font-family: poppins;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  background-color: #2687d7;
  margin-top: 40px;
  min-width: 100%;
  min-height: 48px;
  text-align: center;
  justify-content: center;
}

.login-signup-button .span-1,
.login-signup-button .span-2 {
  display: block;
  transition: all 0.3s ease-in-out;
}

.login-signup-button:active {
  transform: scale(0.95);
}

.login-forgot-password-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-forgot-password-text {
  color: #2687d7;
  cursor: pointer;
  margin: 0px;
}

.otp-form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-inputbar {
  width: 30px;
  height: 50px;
  border-radius: 20px;
  text-align: center;
  margin-right: 10px;
  font-size: 16px;
  color: #333;
}

.margin-align {
  margin-top: 8%;
  margin-bottom: 3%;
  font-family: "Times New Roman", Times, serif;
  font-size: 17px;
}

#verify-btn {
  cursor: pointer;
  display: inline-block;
  align-content: flex-end;
  margin-top: 30px;
  background: #589bca;
  color: white;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  width: 35%;
}

.login-right-container {
  min-width: 65vw;
  /* margin-top: 100px; */
  /* background: url("../../assets/IMAGES/blob-haikei-3.svg") left top repeat; */
  background-repeat: no-repeat;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .login-main-container {
    flex-direction: column;
    /* padding: 20px; */
    align-items: center;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;
  }

  .sub-left-container {
    width: 95%;
    padding-bottom: 20px;
    background-color: #fff;
    filter: drop-shadow(6px 18px 60px rgba(13, 80, 96, 0.25));
    border-radius: 0px 30px 30px 0px;
    /* margin-top: 5%; */
    /* margin-bottom: 4%; */
    margin-right: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .sub-right-container {
    width: 100%;
    margin: 0;
  }
  .login-right-container {
    min-width: 0vw;
    height: 0vh;
    display: none;
    background-color: #000;
  }

  .login-header-text {
    font-size: 22px;
  }

  .login-input-div {
    padding-bottom: 15px;
  }

  .login-signup-button {
    min-width: 100%;
    min-height: 40px;
  }

  .login-forgot-password-container {
    justify-content: center;
    margin-top: 20px;
  }
}
